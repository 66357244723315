import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import "./condiciones-legales.scss";

export default function CondicionesLegales() {
    return (
        <React.Fragment>
            <SEO title="Unsubscribe" />
            <Layout pageId="unsubscribe">
                <div className="privacy-policy-container">
                    <h1>Eliminar subscripción</h1>
                    <center>
                        <p>
                            Puedes eliminar tu subscripción enviando un correo a{" "}
                            <strong>admin@negligenciasdeabogados.net</strong>{" "}
                            con el título "Unsubscribe"
                        </p>
                    </center>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </Layout>
        </React.Fragment>
    );
}
